import React from 'react'
// import gridBG from '../images/grid-bg.png'
export default function grid(title) {
  return (
    <div className='bg-white'>
    <section className='container mx-auto pb-20 sm:pb-20 p-2 sm:p-11'>
       <h2 className="text-[red] text-5xl mt-5 mb-5 tracking-wider mx-auto text-center"></h2>
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-1 sm:gap-4 mx-auto">
      <div class="square bg-[red] bg-center bg-cover hue-rotate-15 bg-[url('../media/grid.webp')]">
        <div class="content posi">
        </div>
      </div>
      <div class="square bg-[red] bg-center bg-center bg-cover hue-rotate-15 bg-[url('../media/grid.webp')]">
        <div class="content posi">
        </div>
      </div>
      <div class="square bg-[red] bg-center bg-cover hue-rotate-15 bg-[url('../media/grid.webp')]">
        <div class="content posi">
        </div>
      </div>
      <div class="square bg-[red] bg-center bg-cover hue-rotate-15 bg-[url('../media/grid.webp')]">
        <div class="content posi">
        </div>
      </div>
      <div class="square bg-[red] bg-center bg-cover hue-rotate-15 bg-[url('../media/grid.webp')]">
        <div class="content posi">
        </div>
      </div>
      <div class="square bg-[red] bg-center bg-cover hue-rotate-15 bg-[url('../media/grid.webp')]">
        <div class="content posi">
        </div>
      </div>
      
      </div>
    </section>
    </div>
  )
}
