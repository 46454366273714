//https://www.gatsbyjs.com/docs/how-to/sourcing-data/sourcing-from-wordpress/
import Header from "../components/header"
import Seo from "../components/seo"
import Footer from "../components/footer"
import Grid from "../components/grid"
import React from "react"
import { graphql, Link } from "gatsby"

const ComponentName = ({ data }) => { return (
  <>
    <Seo title="Crypto & sport NFT's backed by the BJJ community" />
        <Header />
        <section className='bg-bjjblue'>
            <div className='container m-auto flex justify-center flex-col sm:p-11 w-ful min-h-[40rem] p-7'>
              <h1 className="text-white text-4xl sm:text-6xl 2xl:text-5xl sm:mt-11 sm:mb-7 mt-1 mb-1 font-extrabold tracking-wider">BJJ and Crypto news</h1>
              <p className='text-white lg:text-xl pb-11'> We are proud to present bjjtoken.io that is founded by experienced BJJ Black belts, for the needs and benefits of the BJJ community.</p>
            </div>
          </section>
          <Grid/>
        <Footer />

    {/* <section className="bg-[#110e36] text-white">
        { data.allWpPost.nodes.map(post => (
          <article className="max-w-4xl	 mx-auto" key={post.id}>
            <h1>{post.title}</h1>
            <a src="test"> slug </a>
            <Link className='text-red m-[0.8rem]' to={"/" + post.slug}>...</Link>

            <p className="mt-2" dangerouslySetInnerHTML={{__html: post.content}}></p>
          </article> 
          )
        ) 
      }
    </section> */}
  </> ) }

export const query = graphql`
  {
    allWpPost {
      nodes {
        title
        slug
        content
        id
      }
    }
  }
`

export default ComponentName
